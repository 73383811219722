import { useEffect, useState } from 'react';

import {  getAsteriskStyle } from './utils';

import styles from './Asterisk.module.scss';

const Asterisk = ({children, subText=false, position, translate, scale, interaction=false}) => {

    const [animatedScale, setAnimatedScale] = useState(scale)

    // Handle asterisk animation if set to true
    useEffect(()=>{

        if(interaction?.animate){
            var interval, timeout;
            interval = setInterval(
                async()=>{
                    setAnimatedScale(scale*0.8)
                    timeout = await setTimeout(() => {
                        setAnimatedScale(scale)
                    }, 100);
                }
            ,5000)
        }

        return () => {
            clearInterval(interval), clearTimeout(timeout)
        }

    }, [interaction, scale])

    let asteriskStyle = getAsteriskStyle(position, translate, scale, interaction, animatedScale)

    function handleClick(e){
        e.stopPropagation()
        interaction?.action && interaction.action()
    }

    return (

        <div className={`${styles.asterisk}`} style={asteriskStyle} onClick={(e)=>handleClick(e)}>

                <div className={styles.asterisk__texts}>
                    <div className={styles.asterisk__mainText}>
                        {children}
                    </div>

                    {subText &&
                        <div className={styles.asterisk__subText}>{subText}</div>
                    }
                </div>

                <div className={styles.asterisk__stars}>
                    {[0,1].map((star)=>
                    <div className={styles.asterisk__star} key={star}>
                        <svg width="164" height="156" viewBox="0 0 164 156" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M81.8453 0.216187L101.105 59.4912H163.43L113.008 96.1251L132.268 155.4L81.8453 118.766L31.423 155.4L50.6826 96.1251L0.260315 59.4912L62.5857 59.4912L81.8453 0.216187Z" fill={'#ff4c00'} fillOpacity="1"/>
                        </svg>                            
                    </div>
                    )}
                </div>

        </div>

     );
}

export default Asterisk;
