import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { v4 } from "uuid";
import { xoIntercom, KlaviyoHelper, track, validate, trackChoosePlan } from "utils";
import {  E_FILTER_CLEARED, E_FILTER_SELECTED, E_ACCOUNT_CREATED } from 'utils/constants';
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";
import {
    getCSRFCookie,
    getUserStatus,
    getFavoriteVideos,
    getNewReleases,
    getRecentBlogPosts,
    addFavoriteVideo,
    removeFavoriteVideo,
    searchVideosByTitle,
    signUp,
    updatePlanSelected,
} from "api";
import {
    useWindowSize,
    breakPoints,
    tryLocalStorage,
    identifySegment,
} from "utils";
import { initialSignUpInfo } from "utils/signup";

const AppContext = React.createContext();

const DEFAULT_USER_STATUS = {
    loggedIn: false,
    isActive: false,
    hasFetched: false,
}

export default function AppProvider(props) {
    const xoSessionId = useMemo(() => v4(), []);
    const [favorites, setFavorites] = useState([]);
    const [newReleases, setNewReleases] = useState([]);
    const [recentBlogPosts, setRecentBlogPosts] = useState([]);
    const [userStatus, setUserStatus] = useState(DEFAULT_USER_STATUS);
    const previousUserStatus = useRef(null);
    const windowSize = useWindowSize();
    const [isMobile, setIsMobile] = useState();
    const [appLoading, setAppLoading] = useState(false);
    const [globalAccountInfo, setGlobalAccountInfo] = useState(false);
    const [dynamicRightMenu, setDynamicRightMenu] = useState(false);
    const [navHeight, setNavHeight] = useState(false);
    const [signUpInfo, setSignUpInfo] = useState(initialSignUpInfo);
    const [openDynamicRightMenu, setOpenDynamicRightMenu] = useState(false);
    const router = useRouter();
    const [filteredVideos, setFilteredVideos] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [lastSubmittedSearch, setLastSubmittedSearch] = useState('');
    const [isSearchEnabled, setIsSearchEnabled] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false);
    const [isTerminated, setIsTerminated] = useState(false);
    const [submittedSearchValue, setSubmittedSearchValue] = useState('');
    const isIOS = useMemo(() => {
        if (typeof window !== "undefined") {
            return (
                [
                    "iPad Simulator",
                    "iPhone Simulator",
                    "iPod Simulator",
                    "iPad",
                    "iPhone",
                    "iPod",
                ].includes(navigator.platform) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes("Mac") &&
                    "ontouchend" in document)
            );
        }
        return false;
    }, []);

    const isTapHoldExperiment = !isIOS && isMobile;
    const variantTapHold = userStatus.id % 2 === 0 ? 0 : 1;
    const playOnTap = isTapHoldExperiment && variantTapHold === 1;

    useEffect(() => {
        getCSRFCookie().then(() =>
            getUserStatus().then((res) => {
                const cachedSignUpInfo = tryLocalStorage.get("signUpInfo");
                setSignUpInfo({
                    ...signUpInfo,
                    ...cachedSignUpInfo,
                    email: res.data?.loggedIn ? cachedSignUpInfo?.email : "",
                    password: "",
                    userCreated: res.data?.loggedIn || false,
                    initialized: true,
                    completedSignUp: false,
                    abPriceDaily: res.data?.abPriceDaily,
                    abPriceMonthly: res.data?.abPriceMonthly,
                    abPriceAnnual: res.data?.abPriceAnnual,
                });
                if (res.data?.aStatus === "suspended"){
                    setIsSuspended(true);
                } if (res.data?.aStatus === "terminated"){
                    setIsTerminated(true);
                }
                res.status === 200
                    && setUserStatus({
                        ...res.data,
                        hasFetched: true,
                    });
            })
        );
        getRecentBlogPosts("article").then((res) => {
            setRecentBlogPosts(res.data.blogs);
        });
        xoIntercom.boot({});
        router.events.on("routeChangeStart", () => setAppLoading(true));
        router.events.on("routeChangeComplete", () => setAppLoading(false));

        if (window !== undefined) {
            if (typeof window.addEventListener === "function") {
                const openPopupBtns =
                    document.querySelectorAll(".open-popup-btn");
                openPopupBtns.forEach((btn) => {
                    btn.addEventListener("click", (e) => {
                        const hash = Math.random().toString(36).substring(2, 8);
                        const freeMovieSlug = btn.getAttribute(
                            "data-free-movie-slug"
                        );
                        history.pushState(
                            {},
                            "",
                            `?freeMovie=${freeMovieSlug}&hash=${hash}`
                        );
                    });
                });
            }

            (function (history) {
                const pushState = history.pushState;
                history.pushState = function (state) {
                    if (typeof history.onpushstate == "function") {
                        history.onpushstate({ state: state });
                    }
                    setTimeout(() => {
                        tryOpenKlaviyoFreeMoviePopUp();
                    }, 200);
                    return pushState.apply(history, arguments);
                };
            })(window.history);
        }

        return () => {
            router.events.off("routeChangeStart");
            router.events.off("routeChangeComplete");
        };
    }, []);

    function tryOpenKlaviyoFreeMoviePopUp() {
        const freeMovie = new URLSearchParams(window.location.search).get(
            "freeMovie"
        );
        if (freeMovie) {
            const Klaviyo = new KlaviyoHelper();
            Klaviyo.showPopUp("VJpUtx");
            window.addEventListener("klaviyoForms", function (e) {
                if (e.detail.type == "submit") {
                    router.push(`/free-movie/${freeMovie}`);
                }
            });
        }
    }

    async function searchVideosContextFunction(searchEntry){
        setAppLoading(true)
        setLastSubmittedSearch(searchEntry)
        setSubmittedSearchValue(searchEntry)
        const { data } = await searchVideosByTitle(searchEntry);
        setFilteredVideos(data);
        setAppLoading(false)
    }

    useEffect(() => {
        const userHasJustLoggedIn = !previousUserStatus.current?.loggedIn && userStatus.loggedIn
        if (userHasJustLoggedIn && userStatus.isActive) {
            dataLayer.push({
                'user_id': userStatus.id
            })
            identifySegment(userStatus)
            getFavoriteVideos().then((res) =>
                setFavorites(res.data.user_video_favorites)
            );
            getNewReleases().then((res) =>
                setNewReleases(res.data.new_releases)
            );
        }
        previousUserStatus.current = userStatus
    }, [userStatus, previousUserStatus]);

    useEffect(() => {
        setIsMobile(windowSize.width <= breakPoints.mobile);
    }, [windowSize]);

    useEffect(() => {
        if (signUpInfo.initialized) {
            tryLocalStorage.set("signUpInfo", {
                ...signUpInfo,
                password: "",
                cardNumber: "",
                cvv: "",
                accessCode: { code: false, type: null, text: "", valid: null },
            });
        }
    }, [signUpInfo]);

    const addFavorite = (video) => {
        addFavoriteVideo(video.video_id);
        setFavorites([...favorites, video]);
    };
    const removeFavorite = (video) => {
        removeFavoriteVideo(video.video_id);
        setFavorites(
            favorites.filter((favorite) => favorite.video_id !== video.video_id)
        );
    };

    const [activeMainCategory, setActiveMainCategory] = useState("")

    function handleClickMainCategory(category){
        track(E_FILTER_SELECTED, {filter_type: "main cateogry", filter_name: category}, true)
        const newActiveMainCategoryUrlString = `type=${encodeURIComponent(category)}`
        const activeFiltersString = activeFilters.length ? `&tags=${encodeURIComponent(activeFilters.join(','))}` : '';
        router.push(`/watch?${newActiveMainCategoryUrlString}${activeFiltersString}`, undefined, { shallow: true})
    }

    async function handleCreateUser(){
        window?.scrollTo({top: 0, behavior: 'smooth'});
        if(canProceedSignUp()){
            if(signUpInfo.userCreated){
                proceed()
            }else{
                setAppLoading(true)
                let response;
                try {
                    response = await signUp({email: signUpInfo.email, password: signUpInfo.password, anonymousId: window?.analytics?.user()?.anonymousId()}, signUpInfo.shouldAb)
                    if(response.data.status === "success"){
                        setApiHint({...apiHint, message:""})
                        setSignUpInfo({...signUpInfo, userCreated: true})
                        track(E_ACCOUNT_CREATED, true)
                        setPriceAssigned(response.data.price);
                        proceed()
                    }else{
                        let message = response?.data?.errors[0]
                        if (message === "These credentials do not match our records"){
                            message = <>Your email format is incorrect or already taken. If you already have an account please log in <Link href="/login"><span>here</span></Link></>
                        }
                        setApiHint({message, type:"error"})
                    }
                } catch (e) {
                    console.log('error: ', e)
                    Sentry.captureException(e);
                    setApiHint({type:"error", message: "An unexpected occured. Please try clearing your cookies & cache or trying from a different device/browser."})
                }
                setAppLoading(false);
            }
        }
    }

    const canProceedSignUp = useCallback(()=>{
        return validate.email(signUpInfo.email).valid && validate.password(signUpInfo.password).valid
    }, [signUpInfo.email, signUpInfo.password, userStatus.loggedIn])

    const proceed = () => router.push({ query: { ...router.query, step: 2 } }, undefined)

    const [apiHint, setApiHint] = useState({type:"", message:""})
    const [priceAssigned, setPriceAssigned] = useState("");

    function handleClick(){
        updatePriceSelected()
        trackChoosePlan({...signUpInfo}, router.asPath)
        router.push({ query: { ...router.query, step: 3 } }, undefined)
    }

    async function updatePriceSelected() {
        const response = await updatePlanSelected(signUpInfo.plan);
    }

    const value = useMemo(() => {
        return {
            userStatus,
            setUserStatus,
            windowSize,
            isMobile,
            appLoading,
            setAppLoading,
            globalAccountInfo,
            setGlobalAccountInfo,
            navHeight,
            setNavHeight,
            dynamicRightMenu,
            setDynamicRightMenu,
            signUpInfo,
            setSignUpInfo,
            favorites,
            setFavorites,
            addFavorite,
            removeFavorite,
            newReleases,
            recentBlogPosts,
            openDynamicRightMenu,
            setOpenDynamicRightMenu,
            isIOS,
            isTapHoldExperiment,
            variantTapHold,
            playOnTap,
            xoSessionId,
            filteredVideos,
            setFilteredVideos,
            searchVideosContextFunction,
            activeFilters,
            setActiveFilters,
            searchInputValue,
            setSearchInputValue,
            isSearchEnabled,
            setIsSearchEnabled,
            lastSubmittedSearch,
            setLastSubmittedSearch,
            activeMainCategory,
            setActiveMainCategory,
            handleClickMainCategory,
            handleCreateUser,
            canProceedSignUp,
            proceed,
            apiHint,
            setApiHint,
            priceAssigned,
            setPriceAssigned,
            handleClick,
            updatePriceSelected,
            isSuspended,
            setIsSuspended,
            isTerminated,
            setIsTerminated,
            submittedSearchValue,
            setSubmittedSearchValue
        };
    }, [
        userStatus,
        windowSize,
        isMobile,
        appLoading,
        globalAccountInfo,
        navHeight,
        dynamicRightMenu,
        signUpInfo,
        favorites,
        newReleases,
        recentBlogPosts,
        openDynamicRightMenu,
        isIOS,
        isTapHoldExperiment,
        variantTapHold,
        playOnTap,
        xoSessionId,
        filteredVideos,
        setFilteredVideos,
        searchVideosContextFunction,
        activeFilters,
        setActiveFilters,
        searchInputValue,
        setSearchInputValue,
        isSearchEnabled,
        setIsSearchEnabled,
        lastSubmittedSearch,
        setLastSubmittedSearch,
        submittedSearchValue,
        setSubmittedSearchValue
    ]);

    return <AppContext.Provider value={value} {...props} />;
}

export function useAppContext() {
    const context = React.useContext(AppContext);
    if (!context) {
        throw new Error("useUser must be inside AppContext provider.");
    }
    return context;
}
