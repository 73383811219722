import React from 'react';
import { useAppContext } from 'contexts/AppContext';
import { useScrollPosition} from 'utils';
import DesktopNav from "./DesktopNav/DesktopNav";
import MobileNav from "./MobileNav/MobileNav";
import styles from './Nav.module.scss';

const Nav = ({dark=false, backButton=false, noTabs=false}) => {

    const { userStatus, appLoading, setAppLoading } = useAppContext()
    const scrollPosition = useScrollPosition();
    const MARKETING_DOMAIN = process.env.NEXT_PUBLIC_MARKETING_URL
    const tabs = {
        porn: {path: "/watch?type=porn", label: "Porn"},
        eduporn: {path: "/watch?type=eduporn", label: "Eduporn"},
        guidedSex: {path: "/watch?type=guided+sex", label: "Guided Sex"},
        videos: {path: "/watch", label: "Videos"},
        journeys: {path: `${MARKETING_DOMAIN}/journeys`, label: "Journeys"},
        articles: {path: `${MARKETING_DOMAIN}/read`, label: "Articles"},
        ethicalPorn: {path: `${MARKETING_DOMAIN}/ethical-porn`, label: "Ethical Porn Guide"},
        pornForCouples: {path: `${MARKETING_DOMAIN}/porn-for-couples`, label: "Porn For Couples"},
        pornForWomen: {path: `${MARKETING_DOMAIN}/porn-for-women`, label: "Porn For Women"},
        moreArticles: {path: `${MARKETING_DOMAIN}/read`, label: "More Articles"},
        about: {path: `${MARKETING_DOMAIN}/about`, label: "About"},
        login: {path: "/login", label: "Log In"},
        logout: {path: null, label: "Log Out"},
        signup: {path: "/signup", label: "Signup"},
        account: {path: "/account", label: "Account"},
        admin: {path: "/admin/dashboard", label: "Admin Area"},
    }

    return (
        <div className={`${styles.nav} ${noTabs ? styles["nav-noTabs"] : ""}`}>
            <span className={`${styles.nav__mobile} ${dark ? styles[`nav__mobile-dark`] : ""}`}>
                <MobileNav scrollPosition={scrollPosition} userStatus={userStatus} appLoading={appLoading} setAppLoading={setAppLoading} tabs={tabs} noTabs={noTabs} backButton={backButton} />
            </span>
            <span className={`${styles.nav__desktop} ${dark ? styles[`nav__desktop-dark`] : ""}`}>
                <DesktopNav userStatus={userStatus} appLoading={appLoading} setAppLoading={setAppLoading} tabs={tabs} noTabs={noTabs} />
            </span>
        </div>
     );
}

export default Nav;
