import React, { useState, useRef, useEffect } from 'react'
import { useRouter } from 'next/router';
import { useAppContext } from 'contexts/AppContext';
import { handleLogout, backBtn, tabIsActive, track, hamburguerMenu, afterglowLogoUpdatedMobileNav, arrowDownRounded} from 'utils';
import { E_CTA_CLICKED } from 'utils/constants';
import Link from 'next/link';
import LineLoader from '@/components/elements/LineLoader/LineLoader';
import styles from './MobileNav.module.scss';

const MobileNav = ({scrollPosition, userStatus, appLoading, setAppLoading, tabs, noTabs, backButton }) => {

    const { navHeight, setNavHeight, dynamicRightMenu, openDynamicRightMenu, setOpenDynamicRightMenu, handleClickMainCategory } = useAppContext();
    const router = useRouter()
    const navRef = useRef();
    const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false)


    useEffect(()=>{
        setNavHeight({...navHeight, mobile: navRef.current?.offsetHeight})
    }, [navRef.current?.offsetHeight]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {        
        function closeMenuReference (){
            setOpenHamburgerMenu(false)
        }
        router.events.on('routeChangeStart', closeMenuReference)
        return () => {
          router.events.off('routeChangeStart', closeMenuReference)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    function trackClickedSignup(){
        track(E_CTA_CLICKED, {
            type: 'Tab',
            text: 'Signup',
            page_url: router.pathname,
            destination: '/signup',
            location: 'Nav menu'
        });
    }

    return ( 
        <nav className={`${styles.nav} ${scrollPosition > 50 ? styles['nav-scrolled'] : ''} ${(openHamburgerMenu || openDynamicRightMenu) ? styles['nav-open'] : ''} ${noTabs ? styles['nav-noTabs'] : ""}`} onClick={()=>{setOpenHamburgerMenu(false), setOpenDynamicRightMenu(false)}} ref={navRef}>

            <div>
                {!backButton  ? 
                <div className={styles.nav__hamburger} onClick={(e)=>{e.stopPropagation(), setOpenHamburgerMenu(true)}}>
                    {hamburguerMenu()}
                </div>
                :
                <div className={styles.nav__backBtn} onClick={()=>router.back()}>
                    {backBtn(9)}
                </div>
                }
                <Link href={userStatus?.isActive ? "/watch" : "/"} passHref>
                    <div className={styles.nav__logo}>
                        <img src="/next/new_ag_logo_no_orb.png" className={styles['mobile_nav_new_logo']} alt="logo" />
                    </div>
                </Link>
                
                {dynamicRightMenu && 
                    <div className={styles.nav__filters} onClick={(e)=>{e.stopPropagation(), setOpenDynamicRightMenu(true)}}>
                        {dynamicRightMenu.icon}
                    </div>
                }
            </div>

            <div className={`${styles.nav__hamburgerMenu} ${openHamburgerMenu ? styles['nav__hamburgerMenu-open'] : ''}`} onClick={(e)=>e.stopPropagation()}>
                <Link href="/" passHref>
                    <div className={styles.nav__hamburgerMenuLogo}>
                        {afterglowLogoUpdatedMobileNav()}
                    </div>
                </Link>
                <ul>
                    <li className={`${styles.nav__tab} ${tabIsActive(tabs.porn.path, tabs?.porn) ? styles['nav__tab-active'] : ''}`} onClick={()=>{handleClickMainCategory("porn")}}>
                        <Link href={tabs.porn.path} passHref scroll={false}>
                            {tabs.porn.label}
                        </Link>
                    </li>
                    <li className={`${styles.nav__tab} ${tabIsActive(tabs.eduporn.path, tabs?.eduporn) ? styles['nav__tab-active'] : ''}`} onClick={()=>handleClickMainCategory("eduporn")}>
                        <Link href={tabs.eduporn.path} scroll={false}>
                            {tabs.eduporn.label}
                        </Link>
                    </li>
                    <li className={`${styles.nav__tab} ${tabIsActive(tabs.guidedSex.path, tabs?.guidedSex) ? styles['nav__tab-active'] : ''}`} onClick={()=>handleClickMainCategory("guided sex")}>
                        <Link href={tabs.guidedSex.path} scroll={false}>
                            {tabs.guidedSex.label}
                        </Link>
                    </li>
                    <li className={`${styles.nav__tab} ${tabIsActive(router.pathname, tabs?.articles) ? styles['nav__tab-active'] : ''}`}>
                        <Link href={tabs.articles.path}>
                            <span>{tabs.articles.label}</span>
                        </Link>
                        <div className={styles.nav__articles_link_arrow_icon_mobile}>
                            {arrowDownRounded("white", 16, 16)}
                        </div>
                    </li>
                    <li className={`${styles.nav__tab} ${styles.mobile_nav_articles_indented_links} ${tabIsActive(tabs.ethicalPorn.path, tabs?.ethicalPorn) ? styles['nav__tab-active'] : ''}`}>
                        <Link href={tabs.ethicalPorn.path}>
                            <span>{tabs.ethicalPorn.label}</span>
                        </Link>
                    </li>
                    <li className={`${styles.nav__tab} ${styles.mobile_nav_articles_indented_links} ${tabIsActive(tabs.pornForCouples.path, tabs?.pornForCouples) ? styles['nav__tab-active'] : ''}`}>
                        <Link href={tabs.pornForCouples.path}>
                            <span>{tabs.pornForCouples.label}</span>
                        </Link>
                    </li>
                    <li className={`${styles.nav__tab} ${styles.mobile_nav_articles_indented_links} ${tabIsActive(tabs.pornForWomen.path, tabs?.pornForWomen) ? styles['nav__tab-active'] : ''}`}>
                        <Link href={tabs.pornForWomen.path}>
                            <span>{tabs.pornForWomen.label}</span>
                        </Link>
                    </li>
                    <li className={`${styles.nav__tab} ${styles.mobile_nav_articles_indented_links} ${tabIsActive(tabs.moreArticles.path, tabs?.moreArticles) ? styles['nav__tab-active'] : ''}`}>
                        <Link href={tabs.moreArticles.path}>
                            <span>{tabs.moreArticles.label}</span>
                        </Link>
                    </li>
                    <li className={`${styles.nav__tab} ${tabIsActive(tabs.about.path, tabs?.about) ? styles['nav__tab-active'] : ''}`}>
                        <Link href={tabs.about.path} passHref>
                            <span>{tabs.about.label}</span>
                        </Link>
                    </li> 
                    {userStatus?.loggedIn &&
                    <li className={`${styles.nav__tab} ${tabIsActive(tabs.account.path, tabs?.account) ? styles['nav__tab-active'] : ''}`}>
                        <Link href={tabs.account.path} passHref>
                            <span>{tabs.account.label}</span>
                        </Link>
                    </li>
                    }
                    {userStatus?.loggedIn ?
                        <li onClick={()=>{setOpenHamburgerMenu(false), handleLogout(setAppLoading)}} className={styles.mobile_nav_account_links}>
                            {tabs.logout.label}
                        </li>
                        :
                        <li className={`${styles.nav__tab} ${styles['mobile_nav_account_links']} ${tabIsActive(router.pathname, tabs?.login) ? styles['nav__tab-active'] : ''}`}>
                            <Link href={tabs.login.path} passHref>
                                <span>{tabs.login.label}</span>
                            </Link>
                        </li>
                    }
                    {userStatus?.loggedIn && userStatus?.isAdmin &&
                    <li className={styles.mobile_nav_account_links}>
                        <a href={tabs.admin.path}>{tabs.admin.label}</a>
                    </li>
                    }
                    {!userStatus?.isActive && 
                        <li className={`${styles.nav__tab} ${styles.mobile_nav_account_links} ${tabIsActive(router.pathname, tabs?.signup) ? styles['nav__tab-active'] : ''}`} onClick={trackClickedSignup}>
                            <Link href={tabs.signup.path} prefetch={false} passHref>
                                <span>
                                    {tabs.signup.label}
                                </span>
                            </Link>
                        </li>
                    } 
                </ul>
            </div>
            
            {dynamicRightMenu &&
                <div className={`${styles.nav__dynamicRightMenu} ${openDynamicRightMenu ? styles['nav__dynamicRightMenu-open'] : ""}`} onClick={(e)=>e.stopPropagation()}>
                    {dynamicRightMenu.markup}
                </div>
            }
        
            {(appLoading && !openDynamicRightMenu) &&
            <span>
                <LineLoader />
            </span>
            }

        </nav>
     );
}
 
export default MobileNav;