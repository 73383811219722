import { useState, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppContext } from 'contexts/AppContext';
import {motion, AnimatePresence} from 'framer-motion';
import { handleLogout, tabIsActive, arrowDownRounded } from 'utils';
import Link from 'next/link';
import Button from '../../../elements/Button/Button';
import LineLoader from '../../../elements/LineLoader/LineLoader';
import styles from './DesktopNav.module.scss';
import { TranscribeService } from 'node_modules/aws-sdk/index';


const DesktopNav = ({userStatus, appLoading, setAppLoading, tabs, noTabs }) => {

    const router = useRouter();
    const { setNavHeight, navHeight, handleClickMainCategory } = useAppContext();
    const navRef = useRef();
    const [hovering, setHovering] = useState({
        account:false,
        articles: false
    })

    useEffect(()=>{
        setNavHeight({...navHeight, desktop: navRef.current?.offsetHeight})
    }, [navRef.current?.offsetHeight]) // eslint-disable-line react-hooks/exhaustive-deps

    const slideDownTab = {
        hidden: {
            y: 15,
            opacity: 0,
        },
        visible: {
            y: 25,
            opacity: 1
        },
        exitHidden: {
            y: 15,
            opacity: 0,
            transition: {
                default: { duration: .1 },
            }
        }
    }

    return (
        <nav className={`${styles.nav} ${noTabs ? styles['nav-noTabs'] : ""}`} ref={navRef}>
            <Link href={userStatus?.isActive ? "/watch" : "/"}>
                <a>
                    <div className={styles.nav__logo}>
                        <img src="/next/new_ag_logo_no_orb.png" alt="logo" />
                    </div>
                </a>
            </Link>
            <ul className={styles.nav__tabs}>
                <li className={`${styles.nav__tab} ${tabIsActive(tabs.porn.path, tabs?.porn) ? styles['nav__tab-active'] : ''}`} onClick={()=>handleClickMainCategory("porn")}>
                    <Link href={tabs.porn.path} scroll={false}>
                        <div>
                            {tabs.porn.label}
                        </div>
                    </Link>
                </li>
                <li className={`${styles.nav__tab} ${tabIsActive(tabs.eduporn.path, tabs?.eduporn) ? styles['nav__tab-active'] : ''}`} onClick={()=>handleClickMainCategory("eduporn")}>
                    <Link href={tabs.eduporn.path} scroll={false}>
                        <div>
                            {tabs.eduporn.label} 
                        </div>      
                    </Link>
                </li>
                <li className={`${styles.nav__tab} ${tabIsActive(tabs.guidedSex.path, tabs?.guidedSex) ? styles['nav__tab-active'] : ''}`} onClick={()=>handleClickMainCategory("guided sex")}>
                    <Link href={tabs.guidedSex.path} scroll={false}>
                        <div>
                            {tabs.guidedSex.label}
                        </div>
                    </Link>
                </li>
                <li className={styles.nav__dropDownContainer}>
                    <div className={`${styles.nav__parentTab} ${styles.nav__tab} ${tabIsActive(router.pathname, tabs?.articles) ? styles['nav__tab-active']: ''}`} onMouseEnter={()=>setHovering({...hovering, articles:true})} onMouseLeave={()=>setHovering({...hovering, articles:false})}>
                        <Link href={tabs.articles.path}>
                            {tabs.articles.label}
                        </Link>
                        <div className={styles.nav__articles_icon_container}>
                            <div className={styles.nav__articles_link_arrow_icon}>
                                {arrowDownRounded("white", 16, 16)}
                            </div>
                        </div>
                    </div>

                    <AnimatePresence>
                        {hovering.articles &&
                        <>
                            <div className={styles.nav__articles_dropdown_container}>
                                <div className={styles.nav__articles_offset_outline_box}>
                                    <motion.div className={styles.nav__dropDownTab} onMouseEnter={()=>setHovering({...hovering, articles:true})} onMouseLeave={()=>setHovering({...hovering, articles:false})} variants={slideDownTab} initial="hidden" animate="visible" exit="exitHidden">
                                        <div className={`${styles.nav__tab} ${tabIsActive(tabs.ethicalPorn.path, tabs?.ethicalPorn) ? styles['nav__tab-active'] : ''}`}>
                                        <Link href={tabs.ethicalPorn.path}>
                                            {tabs.ethicalPorn.label}
                                        </Link>
                                        </div>
                                    </motion.div>
                                    <motion.div className={styles.nav__dropDownTab} onMouseEnter={()=>setHovering({...hovering, articles:true})} onMouseLeave={()=>setHovering({...hovering, articles:false})} variants={slideDownTab} initial="hidden" animate={{y: 50,opacity: 1}} exit="exitHidden">
                                        <div className={`${styles.nav__tab} ${tabIsActive(tabs.pornForCouples.path, tabs?.pornForCouples) ? styles['nav__tab-active'] : ''}`}>
                                        <Link href={tabs.pornForCouples.path}>
                                            {tabs.pornForCouples.label}
                                        </Link>
                                        </div>
                                    </motion.div>
                                    <motion.div className={styles.nav__dropDownTab} onMouseEnter={()=>setHovering({...hovering, articles:true})} onMouseLeave={()=>setHovering({...hovering, articles:false})} variants={slideDownTab} initial="hidden" animate={{y: 75,opacity: 1}} exit="exitHidden">
                                        <div className={`${styles.nav__tab} ${tabIsActive(tabs.pornForWomen.path, tabs?.pornForWomen) ? styles['nav__tab-active'] : ''}`}>
                                        <Link href={tabs.pornForWomen.path}>
                                            {tabs.pornForWomen.label}
                                        </Link>
                                        </div>
                                    </motion.div>
                                    <motion.div className={styles.nav__dropDownTab} onMouseEnter={()=>setHovering({...hovering, articles:true})} onMouseLeave={()=>setHovering({...hovering, articles:false})} variants={slideDownTab} initial="hidden" animate={{y: 100,opacity: 1}} exit="exitHidden">
                                        <div className={`${styles.nav__tab} ${tabIsActive(tabs.moreArticles.path, tabs?.moreArticles) ? styles['nav__tab-active'] : ''}`}>
                                        <Link href={tabs.moreArticles.path}>
                                            {tabs.moreArticles.label}
                                        </Link>
                                    </div>
                                    </motion.div>
                                </div>
                            </div>
                        </>
                        }
                    </AnimatePresence>
                </li>
                <li className={`${styles.nav__tab} ${tabIsActive(tabs.about.path, tabs.about) ? styles['nav__tab-active'] : ''}`}>
                    <Link href={tabs.about.path}>
                        {tabs.about.label}
                    </Link>
                </li>

                {userStatus?.loggedIn ?
                <li className={`${styles.nav__dropDownContainer}`}>
                    <div className={`${styles.nav__parentTab} ${styles.nav__tab} ${tabIsActive(tabs.account.path, tabs?.account) ? styles['nav__tab-active']: ''}`} style={{marginRight: 0}} onMouseEnter={()=>setHovering({...hovering, account:true})} onMouseLeave={()=>setHovering({...hovering, account:false})}>
                        <Link href={tabs.account.path}>
                            {tabs.account.label}
                        </Link>
                    </div>
                    <AnimatePresence>
                    {hovering.account &&
                        <>
                            {userStatus?.isAdmin && 
                                <div className={styles.account_dropdown_container_admin}>
                                    <motion.div className={styles.nav__dropDownTab} onMouseEnter={()=>setHovering({...hovering, account:true})} onMouseLeave={()=>setHovering({...hovering, account:false})} variants={slideDownTab} initial="hidden" animate="visible" exit="exitHidden">
                                        <div className={`${styles.nav__tab} ${tabIsActive(tabs.logout.path, tabs?.logout) ? styles['nav__tab-active'] : ''}`} onClick={()=>handleLogout(setAppLoading)}>
                                            {tabs.logout.label}
                                        </div>
                                    </motion.div>
                                    <motion.div className={styles.nav__dropDownTab} onMouseEnter={()=>setHovering({...hovering, account:true})} onMouseLeave={()=>setHovering({...hovering, account:false})} variants={slideDownTab} initial="hidden" animate={{y: 45,opacity: 1}} exit="exitHidden">
                                        <div className={`${styles.nav__tab} ${tabIsActive(tabs.admin.path, tabs?.admin) ? styles['nav__tab-active'] : ''}`} onClick={()=>window.location.href=tabs.admin.path}>
                                            {tabs.admin.label}
                                        </div>
                                    </motion.div>
                                </div>             
                            }
                            {!userStatus?.isAdmin && 
                                <div className={styles.account_dropdown_container_no_admin}>
                                    <motion.div className={styles.nav__dropDownTab} onMouseEnter={()=>setHovering({...hovering, account:true})} onMouseLeave={()=>setHovering({...hovering, account:false})} variants={slideDownTab} initial="hidden" animate="visible" exit="exitHidden">
                                        <div className={`${styles.nav__tab} ${tabIsActive(tabs.logout.path, tabs?.logout) ? styles['nav__tab-active'] : ''}`} onClick={()=>handleLogout(setAppLoading)}>
                                            {tabs.logout.label}
                                        </div>
                                    </motion.div>
                                </div> 
                            }
                        </>
                    }
                    </AnimatePresence>
                </li>
                :
                <>
                <li className={styles.nav__loginPlaceholder}>Log In</li>
                <div className={`${tabIsActive(router.pathname, tabs.login) ? styles['nav__tab-active'] : ''} ${styles.nav__loginBtnContainer}`}>
                    <Link href={tabs.login.path} passHref>
                        <span>
                            <Button theme="transparent">{tabs.login.label}</Button>
                        </span>
                    </Link>
                </div>
                </>
                }
            </ul>
            {appLoading &&
                <LineLoader />
            }
        </nav>
     );
}

export default DesktopNav;
