import { motion } from 'framer-motion';
import styles from './XoLoader.module.scss';

const XoLoader = ({size=50, animateOnMount=true}) => {

    return (
        <div className={`${styles.xoLoader}`}>
            <img src="next/new-animation/afterglow-new-orb.svg" style={{height: '180px'}}/>
        </div>
     );
}

export default XoLoader;
