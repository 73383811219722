import Image from 'next/image';
import { svgFilterColors, socialLinks } from 'utils';
import styles from './SocialIcon.module.scss';

const SocialIcon = ({name, width=32}) => {

    const height = Math.round(width * 0.7)

    return ( 
        <div className={styles.card}>
            <a href={socialLinks[name]}>
                <div style={{width: `${width}px`, filter: svgFilterColors["white"]}}>
                    <Image src={`/social/${name}.svg`} priority={true} width={width} height={height} objectFit="contain" alt={`${name} card`} />
                </div>
            </a>

        </div>
     );
}
 
export default SocialIcon;