import { useEffect, useState } from 'react';
import styles from './PromptContainer.module.scss';

const PromptContainer = ({children, close, show, backgroundColor=false, zIndex=3}) => {

    const [showModal, setShowModal] = useState()
    const [showContainer, setShowContainer] = useState()

    useEffect(()=>{
        setShowModal(false)
        let showTimeout;
        
        if(show){
            setShowContainer(show)
            showTimeout = setTimeout(() => {
                setShowModal(true)
            }, 100);
        }else{
            setShowModal(false)
            showTimeout = setTimeout(() => {
                setShowContainer(show)
            }, 200);
        }

        return () => {
            clearTimeout(showTimeout)
        } 

    }, [show])

    return ( 
        <div className={`${styles.promptContainer} ${!showContainer ? styles["promptContainer-hidden"] : ""} ${!(showModal && showContainer) ? styles["promptContainer-hiding"] : ""}`} onClick={close} style={{backgroundColor:  backgroundColor ? backgroundColor : 'auto', zIndex}}>
            <div className={`${styles.promptContainer__modalWrapper} ${!showModal ? styles["promptContainer__modalWrapper-hidden"] : ""}`}>
                {children}
            </div>
        </div>
     );
}
 
export default PromptContainer;