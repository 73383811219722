import Image from 'next/image';

import styles from './CardIcon.module.scss';

const CardIcon = ({name, border=false, inactive=false, width=32}) => {

    const height = Math.round(width * 0.7)

    return ( 
        <div className={`${styles.card} ${border && styles["card-hasBorder"]} ${inactive ? styles["card-inactive"] : ""}`}>
            
            <div style={{width: `${width}px`}}>
                <Image src={`/payment/${name}.svg`} width={width} height={height} priority={true} objectFit="contain" alt={`${name} card`} />
            </div>

        </div>
     );
}
 
export default CardIcon;