import { useState } from 'react';
import { useRouter } from 'next/router';
import {track} from 'utils';
import { E_CTA_CLICKED } from 'utils/constants/index';
import styles from './Button.module.scss';

const Button = ({children, type="", active=true, domRef=null, onClick=null, theme="rose", testId="", style={}, tracking=false}) => {

    const router = useRouter()
    const [clicked, setClicked] = useState()

    function triggerAction(){
        if(active){

            if(tracking){
                track(E_CTA_CLICKED, {
                    type: 'Button',
                    text: typeof children === "string" ? children : "",
                    page_url: router.pathname,
                    destination: tracking?.destination,
                    location: tracking?.location
                });
            }
        
            if(onClick !== null){
                onClick()
            }
        }
    }

    return ( 
        <button 
        data-testid={testId} 
        data-class="button"
        type={type}
        ref={domRef} 
        className={`${styles.button} ${styles[`button-${theme}`]} ${active ? styles["button-active"] : styles["button-inActive"]} ${clicked ? styles["button-clicked"] : ""}`} 
        onClick={(e)=>{active ? triggerAction() : e.preventDefault()}} 
        onMouseDown={()=>active && setClicked(true)} 
        onMouseUp={()=>active && setClicked(false)} 
        onMouseLeave={()=>setClicked(false)} 
        style={style}>
            {children}
        </button>
     );
}
 
export default Button;