import XoLoader from "@/components/elements/XoLoader/XoLoader";
import styles from './FullScreenLoader.module.scss';
import svgLogoNew from "utils";

const FullScreenLoader = () => {
    return ( 
        <div className={styles.fullScreenLoader}>
            <div className={styles.loader}>
                <XoLoader animateOnMount={false} />
            </div>
        </div>
     );
}
 
export default FullScreenLoader;