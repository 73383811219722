export const getAsteriskStyle = (position, translate, scale, interaction, animatedScale) => {
    return {    
        top: position?.top,
        right: position?.right,
        bottom: position?.bottom,
        left: position?.left,
        transform: `translate(${translate?.x ? translate.x : "0"}, ${translate?.y ? translate.y : "0"}) scale(${interaction?.animate ? animatedScale : scale})`,
        opacity: 1,
    }
}
