import styles from './LineLoader.module.scss';

const LineLoader = () => {
    return ( 

        <div className={styles.lineLoader}>
            <div></div>
        </div>

     );
}
 
export default LineLoader;