import axios from 'axios';

axios.defaults.withCredentials = true;

// Add axios request interceptor to send XO-API-KEY header
axios.interceptors.request.use(
    (config) => {
        config.headers['XO-API-KEY'] = process.env.XO_API_KEY;
        return config;
    }
)

const BASE_URL = process.env.NEXT_PUBLIC_APP_URL;

export const login = (data) => axios.post(`${BASE_URL}/api/login`, data)
export const logout = () => axios.post(`${BASE_URL}/logout`)
export const getUserStatus = (context=false) => request(context, "post", "/api/logged-in")
export const getCSRFCookie = (context=false) => request(context, "get", "/sanctum/csrf-cookie")

export const signUp = (user, shouldAb) => axios.post(`${BASE_URL}/api/register`, {user, shouldAb});
export const signUpExistingUser = () => axios.post(`${BASE_URL}/api/signup-existing`, {});
export const validateAccessCode = (code) => axios.get(`${BASE_URL}/api/activation-codes`, {params: {code}})
export const createSubscription = (object) => axios.post(`${BASE_URL}/api/create-subscription`, object);
export const sendContactForm = (object) => axios.post(`${BASE_URL}/api/contact-form`, object);
export const sendAccountSuspended = (object) => axios.post(`${BASE_URL}/api/account-suspended`, object);
export const getPaymentFailureInfo = () => axios.get(`${BASE_URL}/api/get-payment-failure-info`);

export const getPublicVideo = (video) => axios.get(`${BASE_URL}/api/public/${video}`)
export const getBillingInfo = (context=false) => request(context, "get", "/api/billing-info")
export const updatePassword = (data) => axios.post(`${BASE_URL}/api/update-password`, data)
export const updateBilling = (data) => axios.post(`${BASE_URL}/api/update-billing`, data)
export const updateSubscription = (data) => axios.post(`${BASE_URL}/api/update-subscription`, data)
export const sendPasswordResetEmail = (data) => axios.post(`${BASE_URL}/api/send-pw-reset`, data)
export const updateForgottenPassword = (data) => axios.post(`${BASE_URL}/api/update-pw`, data)
export const cancelSubscription = () => axios.post(`${BASE_URL}/api/cancel-subscription`)
export const updatePlanSelected = (data) => axios.post(`${BASE_URL}/api/update-plan-selected`, data)

export const signUrl = (url) => axios.get(`${BASE_URL}/nextapi/videos/sign?url=${url}`)
export const getVideo = (context=false, slug) => request(context, "get", "/api/video?slug="+slug)
export const getVideosByContentCategory = (context=false, content_categories) => request(context, "get", `/api/videos-by-content-category?content_categories=${JSON.stringify(content_categories)}`)
export const getVideosByContentTypeAndCategory = (context=false, data) => request(context, "get", `/api/filter-by-content-type-and-category?content_categories=${JSON.stringify(data.content_categories)}&content_type=${data.content_type}`)
export const getVideosByContentCategories = (context=false, content_categories) => request(context, "get", `/api/filter-by-content-categories?content_categories=${JSON.stringify(content_categories)}`)
export const getFavoriteVideos = (context=false) => request(context, "get", `/api/favorites`)
export const getNewReleases = (context=false) => request(context, "get", `/api/new-releases`)
export const addFavoriteVideo = (content_id) => axios.post(`${BASE_URL}/api/favorites`, {content_id})
export const searchVideosByTitle = (title_name) => axios.post(`${BASE_URL}/api/search-videos-by-title?q=${title_name}`, {title_name})
export const removeFavoriteVideo = (content_id) => axios.delete(`${BASE_URL}/api/favorites`, {params: {content_id}})
export const recordPlaybackEvent = (event) => axios.post(`${BASE_URL}/api/record-playback-event`, {event})
export const getVideoRecommendations = (context=false, slug) => request(context, "get", `/api/recommendations/stored/${slug}`)

export const getBlogPreviewPost = (context=false, data) => request(context, "get", `/api/blog/${data.slug}?type=${data.type}&preview=true`)
export const getRecentBlogPosts = (type) => axios.get(`/api/blogs/recent?type=${type}`)
export const getBlogsByCategory = (context=false, data) => request(context, "get", `/api/blogs/category?categories=${JSON.stringify(data.categories)}&page=${data.page}`)

export const checkAffiliatePromo = (data) => axios.post(`${BASE_URL}/api/affiliate-promo`, data)
export const subscribeToNewsletter = (data) => axios.post(`${BASE_URL}/api/add-email-to-list`, data)

export const getQuizData = (context=false, questionId) => request(context, "get", `/api/quiz-question?quiz_question_id=${questionId}`)
export const submitQuizAnswer = (answerIds) => axios.post(`${BASE_URL}/api/quiz-answer`, {quiz_answer_ids:answerIds})

export const getJourney = (context=false, slug) => request(context, "get", `/api/journey/${slug}`)
export const setJourneyStep = (data) => axios.post(`${BASE_URL}/api/journey-step`, data)

export const sendReferralLink = (email) => axios.post(`${BASE_URL}/api/send-referral-link`, {email})
export const validateReferee = (email) => axios.post(`${BASE_URL}/api/validate-referee`, {email})

//SSG
export const getContentCategories = () => axios.get(getFullEndpoint(true, '/api/content-categories'))
export const getAllVideos = (slice=false) => axios.get(getFullEndpoint(true, `/api/all-videos${slice ? `?from=${slice.from}&to=${slice.to}` : ''}`))
export const getTrendingVideos = () => axios.get(getFullEndpoint(true, '/api/trending-videos'))
export const getDynamicBannerHomepage = () => axios.get(getFullEndpoint(true, '/api/dynamic-banner-homepage'))
export const getDynamicBannerWatch = () => axios.get(getFullEndpoint(true, '/api/dynamic-banner-watch'))
export const getFreeMovie = (slug) => axios.get(getFullEndpoint(true, `/api/free-movie/${slug}`))
export const getStaticBanners = () => axios.get(getFullEndpoint(true, '/api/static-banners'))

export const getBlogHomePage = () => axios.get(getFullEndpoint(true, `/api/blogs/category`))
export const getTrendingBlogs = () => axios.get(getFullEndpoint(true, `/api/trending-blogs`))
export const getBlogsSlugs = () => axios.get(getFullEndpoint(true, `/api/blogs/slugs`))
export const getBlogPost = (data) => axios.get(getFullEndpoint(true, `/api/blog/${data.slug}?type=${data.type}`))

// Helper functions below
function request(context, method, slug, data={}){
    const ENDPOINT = getFullEndpoint(context, slug)
    if(context){
        const axiosData = getAxiosData(context)
        return axios({...axiosData, method, url: ENDPOINT, data})
    }else{
        return axios[method](ENDPOINT)
    }
}

function getAxiosData(context){
    const { req } = context
    const { cookies } = req
    const XSRF_TOKEN = cookies['XSRF-TOKEN'];
    return {headers: context?.req?.headers ? {...context.req.headers, 'X-XSRF-TOKEN':XSRF_TOKEN, 'Content-Type': 'application/json','accept': 'application/json', 'host': getCleanHostName(), 'sec-fetch-mode':'cors', 'origin':BASE_URL} : undefined, withCredentials: true}
}

function getFullEndpoint(context, slug){
    let baseUrl = BASE_URL
    if(context && process.env.NODE_ENV === "development"){
        baseUrl = 'http://laravel:80'
    }
    return baseUrl + slug
}

function getCleanHostName(){
    return BASE_URL.substring(BASE_URL.indexOf("//") + 2);
}
